import axios from 'axios';
import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_REFRESH,
  AUTH_LOGOUT,
} from '../actions/auth';
import {
  MESSAGE_ADD,
} from '../actions/messages';
// eslint-disable-next-line import/prefer-default-export
const AuthModule = {
  state: {
    token: localStorage.getItem('user-token') || '',
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
  },
  actions: {
    [AUTH_REQUEST]: ({ commit, dispatch }, form) => new Promise((resolve, reject) => {
      axios({ url: `${process.env.VUE_APP_BASE_URL}/auth/jwt`, method: 'POST', data: form })
        .then((resp) => {
          const { token } = resp.data;
          commit(AUTH_SUCCESS, token);
          localStorage.setItem('user-token', token);
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          dispatch(MESSAGE_ADD, { title: 'Login', body: 'You have been successfully logged in.', category: 'success' });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
          dispatch(MESSAGE_ADD, { title: 'Login', body: 'The password was incorrect', category: 'danger' });
        });
    }),
    [AUTH_REFRESH]: ({ commit }) => new Promise((resolve, reject) => {
      axios({ url: `${process.env.VUE_APP_BASE_URL}/auth/refresh`, method: 'GET' })
        .then((resp) => {
          const { token } = resp.data;
          localStorage.setItem('user-token', token);
          axios.defaults.headers.common.Authorization = `Bearer ${token}`;
          commit(AUTH_SUCCESS, token);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
    [AUTH_LOGOUT]: ({ commit }) => new Promise((resolve) => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem('user-token');
      delete axios.defaults.headers.common.Authorization;
      resolve();
    }),
  },
  mutations: {
    [AUTH_SUCCESS]: (state, token) => {
      state.token = token;
    },
    [AUTH_LOGOUT]: (state) => {
      state.token = '';
    },
  },
};
export default AuthModule;
