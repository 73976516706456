import Vue from 'vue';
import uuid4 from 'uuid4';
import {
  MESSAGE_ADD,
  MESSAGE_DELETE,
} from '../actions/messages';

// eslint-disable-next-line import/prefer-default-export
const MessageModule = {
  state: {
    messages: [],
  },
  getters: {
    getMessages: (state) => state.messages,
    hasMessages: (state) => state.messages.length > 0,
  },
  actions: {
    [MESSAGE_ADD]: ({ commit }, payload) => {
      commit(MESSAGE_ADD, {
        id: uuid4(),
        title: payload.title,
        body: payload.body,
        category: payload.category,
      });
    },
    [MESSAGE_DELETE]: ({ commit }, messageID) => {
      commit(MESSAGE_DELETE, messageID);
    },
  },
  mutations: {
    [MESSAGE_ADD]: (state, message) => {
      state.messages.push(message);
    },
    [MESSAGE_DELETE]: (state, messageID) => {
      Vue.set(state, 'messages', state.messages.filter((message) => message.id !== messageID));
    },
  },
};
export default MessageModule;
