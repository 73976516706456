<template lang="pug">
  #app
    Navigation
    .container-fluid.pt-5.px-5
      Messages
      router-view
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import Messages from '@/components/Messages.vue';
import axios from 'axios';

export default {
  name: 'app',
  components: {
    Navigation,
    Messages,
  },
  created() {
    const token = localStorage.getItem('user-token');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  },
};
</script>

<style lang="scss">
</style>
