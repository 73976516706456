import axios from 'axios';
import {
  DISTRIBUTORS_REQUEST,
  DISTRIBUTORS_SUCCESS,
  DISTRIBUTORS_SAVE,
  DISTRIBUTORS_UPDATED,
} from '../actions/distributors';
import {
  MESSAGE_ADD,
} from '../actions/messages';
import {
  AUTH_LOGOUT,
} from '../actions/auth';

// eslint-disable-next-line import/prefer-default-export
const DistributorsModule = {
  state: {
    distributors: [],
    status: '',
  },
  getters: {
    areDistributorsLoaded: (state) => !!state.distributors,
    getDistributors: (state) => state.distributors,
  },
  actions: {
    [DISTRIBUTORS_REQUEST]: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      commit(DISTRIBUTORS_REQUEST);
      axios({ url: `${process.env.VUE_APP_BASE_URL}/distributor/`, method: 'GET' })
        .then((resp) => {
          const { distributors } = resp.data;
          commit(DISTRIBUTORS_SUCCESS, distributors);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
          dispatch(MESSAGE_ADD, { title: 'Logged out', body: 'Your session has expired. Please log in again.', category: 'warning' });
          dispatch(AUTH_LOGOUT);
        });
    }),
    [DISTRIBUTORS_SAVE]: ({ commit, dispatch }, distributor) => new Promise((resolve, reject) => {
      commit(DISTRIBUTORS_SAVE);
      axios({ url: `${process.env.VUE_APP_BASE_URL}/distributor/edit/${distributor.id}`, data: distributor, method: 'POST' })
        .then((resp) => {
          commit(DISTRIBUTORS_UPDATED);
          dispatch(DISTRIBUTORS_REQUEST);
          dispatch(MESSAGE_ADD, { title: 'Distributors', body: 'Distributor have been successfully saved.', category: 'success' });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  },
  mutations: {
    [DISTRIBUTORS_REQUEST]: (state) => {
      state.status = 'loading';
    },
    [DISTRIBUTORS_SUCCESS]: (state, distributors) => {
      state.status = 'success';
      state.distributors = distributors;
    },
    [DISTRIBUTORS_SAVE]: (state) => {
      state.status = 'loading';
    },
    [DISTRIBUTORS_UPDATED]: (state) => {
      state.status = 'success';
    },
  },
};
export default DistributorsModule;
