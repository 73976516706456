<template lang="pug">
  b-toast(:variant="message.category" @hidden="deleteMessage" :id="message.id" v-model="show" :title="message.title" toaster="b-toaster-top-center")
    p {{message.body}}
</template>

<script>
import { MESSAGE_DELETE } from '@/store/actions/messages';

export default {
  name: 'message',
  data() {
    return {
      secondsLeft: 2,
      maxSeconds: 2,
      show: true,
    };
  },
  props: ['message'],
  methods: {
    deleteMessage() {
      this.$store.dispatch(MESSAGE_DELETE, this.message.id);
    },
  },
  created() {
  },
};
</script>
