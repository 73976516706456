<template lang="pug">
  b-navbar.mb-2(toggleable="lg" type="dark" variant="primary")
    b-navbar-brand(to="/") Inventory Management
    b-navbar-toggle(target="nav-collapse")
    b-collapse#nav-collapse(is-nav)
      b-navbar-nav(v-if="isLoggedIn")
        b-nav-item(to="/products" active-class="active") Products
        b-nav-item(to="/categories" active-class="active") Categories
        b-nav-item(to="/distributors" active-class="active") Distributors
        b-nav-item-dropdown(text="Reports" :toggle-class="$route.name.indexOf('Report') > -1 ? 'active' : ''")
          b-dropdown-item(to="/reports/investigation") Needs Investigation
          b-dropdown-item(to="/reports/tracked") Tracked Inventory Report
          b-dropdown-item(to="/reports/low") Low Inventory Report
          b-dropdown-item(to="/reports/distributors/-1") Distributors Report
      b-navbar-nav.ml-auto
        b-nav-item(to="/login" v-if="!isLoggedIn") Login
        b-nav-item(@click="logout" v-if="isLoggedIn") Logout
</template>

<script>
import { mapGetters } from 'vuex';
import { AUTH_LOGOUT, AUTH_REFRESH } from '@/store/actions/auth';

export default {
  name: 'navigation',
  data() {
    return {
      refreshInterval: null,
    };
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'));
    },
  },
  watch: {
    isLoggedIn() {
      if (this.isLoggedIn) {
        this.refreshInterval = setInterval(() => {
          this.$store.dispatch(AUTH_REFRESH);
        }, 300 * 1000);
      } else {
        clearInterval(this.refreshInterval);
        this.$router.push('/login');
      }
    },
  },
};
</script>
