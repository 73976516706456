import axios from 'axios';
import {
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CATEGORIES_SAVE,
  CATEGORIES_UPDATED,
} from '../actions/categories';
import {
  MESSAGE_ADD,
} from '../actions/messages';
import {
  AUTH_LOGOUT,
} from '../actions/auth';

// eslint-disable-next-line import/prefer-default-export
const CategoriessModule = {
  state: {
    categories: [],
    status: '',
  },
  getters: {
    areCategoriesLoaded: (state) => !!state.categories,
    getCategories: (state) => state.categories,
  },
  actions: {
    [CATEGORIES_REQUEST]: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      commit(CATEGORIES_REQUEST);
      axios({ url: `${process.env.VUE_APP_BASE_URL}/category/`, method: 'GET' })
        .then((resp) => {
          const { categories } = resp.data;
          commit(CATEGORIES_SUCCESS, categories);
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
          dispatch(MESSAGE_ADD, { title: 'Logged out', body: 'Your session has expired. Please log in again.', category: 'warning' });
          dispatch(AUTH_LOGOUT);
        });
    }),
    [CATEGORIES_SAVE]: ({ commit, dispatch }, category) => new Promise((resolve, reject) => {
      commit(CATEGORIES_SAVE);
      axios({ url: `${process.env.VUE_APP_BASE_URL}/category/${category.clover_id}`, data: category, method: 'POST' })
        .then((resp) => {
          commit(CATEGORIES_UPDATED);
          dispatch(CATEGORIES_REQUEST);
          dispatch(MESSAGE_ADD, { title: 'Categories', body: `${category.name} has been successfully saved.`, category: 'success' });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  },
  mutations: {
    [CATEGORIES_REQUEST]: (state) => {
      state.status = 'loading';
    },
    [CATEGORIES_SUCCESS]: (state, categories) => {
      state.status = 'success';
      state.categories = categories;
    },
    [CATEGORIES_SAVE]: (state) => {
      state.status = 'loading';
    },
    [CATEGORIES_UPDATED]: (state) => {
      state.status = 'success';
    },
  },
};
export default CategoriessModule;
