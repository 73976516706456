<template lang="pug">
.products-list
  .row
    .col
      h1 Products
  .row
    .col
      b-pagination(
        v-model="currentPage",
        :total-rows="totalRows",
        :per-page="perPage"
      )
      p {{ paginationText }}

    .col
      .row
        b-input-group(:append="searchResults.length + ' products'")
          b-form-input(v-model="searchQuery", debounce="500")
      .row
        .col
          b-form-checkbox(v-model="onlyShowTracked") Only tracked?
          b-form-checkbox(v-model="onlyShowLow") Only low?
          b-form-checkbox(v-model="hideLivestock") Hide livestock?
          b-form-checkbox(v-model="onlyShowNeedsInvestigation") Only Needs Investigation?
        .col.pt-2
          b-form-select(
            v-model="distributorId",
            :options="distributorsOptions"
          )
          b-form-select(v-model="categoryId", :options="categoryOptions")
    .col
      b-button-group
        b-button(@click="sync", variant="primary") Sync
        b-button(@click="saveDirty", variant="success") Save {{ dirtyItems.length }} items
  .row
    b-table(
      :items="searchResults",
      :filter="searchQuery",
      :per-page="perPage",
      :currentPage="currentPage",
      :fields="fields",
      :tbody-tr-class="rowClass",
      v-if="areProductsLoaded",
      small
    )
      template(v-slot:cell(link)="data")
        a(:href="'https://www.clover.com/inventory/m/YPVRAH1F15ZXT/items/' + data.item.clover_id" target="_blank") Link
      template(v-slot:cell(current_stock)="data")
        b-form-input(
          v-model="data.item.current_stock",
          type="number",
          style="width: 100px",
          @change="markDirty(data.item)"
        )
      template(v-slot:cell(minimum_stock)="data")
        b-form-input(
          v-model="data.item.minimum_stock",
          type="number",
          style="width: 100px",
          @change="markDirty(data.item)"
        )
      template(v-slot:cell(distributors)="data")
        b-form-select(
          v-if="data.item.canEditDistributors",
          v-model="data.item.distributors",
          :options="distributorsOptions.filter((d) => d.value > -1)",
          multiple,
          :select-size="getDistributors.length",
          @change="markDirty(data.item)"
        )
        template(v-else)
          div(
            v-for="distributor in getDistributors",
            :key="distributor.id",
            v-if="data.item.distributors.indexOf(distributor.id) > -1"
          ) {{ distributor.name }}
          b-button(size="sm", @click="editDistributors(data.item)") Edit
      template(v-slot:cell(actions)="data")
        b-button-group(size="sm")
          b-button(
            @click="toggleTracking(data.item)",
            :variant="data.item.track_stock ? 'danger' : 'info'"
          ) {{ data.item.track_stock ? 'Disable' : 'Enable' }}
          b-button(@click="saveProduct(data.item)", variant="success") Save
          b-button(
            @click="deleteProduct(data.item)",
            variant="danger",
            v-if="data.item.needs_investigation"
          ) Delete
          b-button(
            @click="acknowledgeProduct(data.item)",
            variant="warning",
            v-if="data.item.needs_investigation"
          ) Mark OK
  .row.text-center(v-if="!areProductsLoaded")
    b-spinner(style="width: 3rem; height: 3rem")
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  PRODUCTS_REQUEST,
  PRODUCTS_SAVE,
  PRODUCTS_DELETE_REQUEST,
  PRODUCTS_SYNC,
} from '@/store/actions/products';
import { DISTRIBUTORS_REQUEST } from '@/store/actions/distributors';
import { CATEGORIES_REQUEST } from '@/store/actions/categories';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default {
  name: 'product-list',
  data() {
    return {
      perPage: 50,
      currentPage: 1,
      searchQuery: '',
      dirtyItems: [],
      distributorId: -1,
      categoryId: -1,
      onlyShowTracked: false,
      onlyShowLow: false,
      hideLivestock: false,
      onlyShowNeedsInvestigation: false,
      fields: [
        'link',
        {
          key: 'sku',
          label: 'SKU',
          sortable: true,
        },
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'cost',
          sortable: true,
          formatter: (val) => {
            if (val) {
              return currencyFormatter.format(val / 100);
            }
            return '';
          },
        },
        {
          key: 'current_stock',
          label: 'Stock',
          sortable: true,
        },
        {
          key: 'minimum_stock',
          label: 'Min Stock',
          sortable: true,
        },
        'distributors',
        {
          key: 'category',
          formatter: (val) => {
            if (val) {
              return val.name;
            }
            return 'N/A';
          },
          sortable: true,
          sortByFormatted: true,
        },
        {
          key: 'category.minimum_stock',
          label: 'Cat. Min Stock',
          sortable: true,
        },
        'actions',
      ],
    };
  },
  methods: {
    rowClass: (item) => {
      if (item.needs_investigation) {
        return 'table-warning';
      }
      if (!item.track_stock) {
        return 'table-secondary text-muted';
      }
      if (
        item.current_stock <= item.minimum_stock
        || (item.category && item.current_stock <= item.category.minimum_stock)
      ) {
        return 'table-danger';
      }
      return 'table-success';
    },
    toggleTracking(product) {
      // eslint-disable-next-line no-param-reassign
      product.track_stock = !product.track_stock;
      this.saveProduct(product);
    },
    saveProduct(product) {
      this.$store.dispatch(PRODUCTS_SAVE, product);
      // eslint-disable-next-line no-param-reassign
      product.canEditDistributors = false;
    },
    deleteProduct(product) {
      // eslint-disable-next-line no-alert, no-restricted-globals
      if (confirm(`Are you sure you want to delete ${product.name}? This will reset all settings for ${product.name}, including distributors and stock threshold.`)) {
        this.$store.dispatch(PRODUCTS_DELETE_REQUEST, product);
        // eslint-disable-next-line no-param-reassign
        product.canEditDistributors = false;
      }
    },
    acknowledgeProduct(product) {
      // eslint-disable-next-line no-param-reassign
      product.needs_investigation = false;
      this.saveProduct(product);
    },
    sync() {
      this.$store.dispatch(PRODUCTS_SYNC);
    },
    markDirty(item) {
      if (this.dirtyItems.indexOf(item) === -1) {
        this.dirtyItems.push(item);
      }
    },
    editDistributors(item) {
      // eslint-disable-next-line no-param-reassign
      item.canEditDistributors = true;
      this.$forceUpdate();
    },
    saveDirty() {
      this.dirtyItems.forEach((product, i) => {
        setTimeout(() => {
          this.$store.dispatch(PRODUCTS_SAVE, product);
        }, 500 * i);
      });
      Vue.set(this, 'dirtyItems', []);
    },
  },
  created() {
    this.$store.dispatch(PRODUCTS_REQUEST);
    this.$store.dispatch(DISTRIBUTORS_REQUEST);
    this.$store.dispatch(CATEGORIES_REQUEST);
  },
  computed: {
    searchResults() {
      const toFilter = this.getProducts
        .filter((product) => (this.hideLivestock
          ? product.category && !product.category.is_livestock
          : true))
        .filter((product) => (this.onlyShowNeedsInvestigation ? product.needs_investigation : true))
        .filter((product) => (this.onlyShowTracked ? product.track_stock : true))
        .filter((product) => (this.onlyShowLow
          ? product.track_stock
              && (product.current_stock <= product.minimum_stock
                || (product.category
                  && product.current_stock <= product.category.minimum_stock))
          : true))
        .filter((product) => (this.distributorId > -1
          ? product.distributors.indexOf(this.distributorId) > -1
          : true))
        .filter((product) => (this.distributorId === -2 ? product.distributors.length === 0 : true))
        .filter((product) => (this.categoryId !== -1
          ? product.category && product.category.clover_id === this.categoryId
          : true));
      if (this.searchQuery !== '') {
        return toFilter.filter(
          (product) => product.name.toLowerCase().indexOf(this.searchQuery) > -1
            || (product.alternative_name
              && product.alternative_name.toLowerCase().indexOf(this.searchQuery)
                > -1)
            || (product.category
              && product.category.name.toLowerCase().indexOf(this.searchQuery)
                > -1)
            || product.clover_id.toLowerCase().indexOf(this.searchQuery) > -1,
        );
      }
      return toFilter;
    },
    totalRows() {
      return this.searchResults.length;
    },
    distributorsOptions() {
      const options = [
        {
          value: -1,
          text: 'All Distributors',
        },
      ];
      options.push({
        value: -2,
        text: 'No Distributo',
      });
      this.getDistributors.forEach((distributor) => {
        options.push({
          value: distributor.id,
          text: distributor.name,
        });
      });
      return options;
    },
    categoryOptions() {
      const options = [
        {
          value: -1,
          text: 'All Categories',
        },
      ];
      const categories = this.getCategories;
      categories
        .sort((a, b) => a.name.localeCompare(b.name))
        .forEach((category) => {
          options.push({
            value: category.clover_id,
            text: category.name,
          });
        });
      return options;
    },
    paginationText() {
      const startIndex = 1 + (this.currentPage - 1) * this.perPage;
      let pageCount = this.searchResults.slice(
        startIndex,
        startIndex + this.perPage - 1,
      ).length;
      if (pageCount === 1) {
        pageCount = 0;
      }
      const endIndex = startIndex + pageCount;

      return `${startIndex} - ${endIndex} of ${this.totalRows}`;
    },
    ...mapGetters([
      'areProductsLoaded',
      'getProducts',
      'getDistributors',
      'getCategories',
    ]),
  },
  filters: {},
  watch: {
    searchQuery(newQuery) {
      this.searchQuery = newQuery.toLowerCase();
    },
  },
};
</script>
