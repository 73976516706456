<template lang="pug">
  .distributor-edit
    .row
      .col
        h1 {{header}}
    .row
      b-form(@submit.prevent="save")
        b-form-group(id="name-group" label="Name: " label-for="name")
          b-form-input(id="name" v-model="distributor.name" type="text" required placeholder="Distributor Name")
        b-button(type="submit" variant="success") Save
</template>

<script>
import { mapGetters } from 'vuex';
import { DISTRIBUTORS_SAVE } from '@/store/actions/distributors';

export default {
  name: 'distributor-edit',
  methods: {
    save() {
      this.$store.dispatch(DISTRIBUTORS_SAVE, this.distributor).then(() => {
        this.$router.push('/distributors');
      });
    },
  },
  computed: {
    ...mapGetters(['getDistributors']),
    distributor() {
      let toEdit = this.getDistributors.find((distributor) => parseInt(this.$route.params.id, 10) === distributor.id);
      if (toEdit === undefined) {
        toEdit = {
          id: -1,
          name: '',
        };
      }
      return toEdit;
    },
    header() {
      if (this.distributor.id === -1) {
        return 'Add New Distributor';
      }
      return `Edit Distributor: ${this.distributor.name}`;
    },
  },
};
</script>
