import Vue from 'vue';
import Vuex from 'vuex';

import ProductsModule from './modules/products';
import MessagesModule from './modules/messages';
import CategoriesModule from './modules/categories';
import DistributorsModule from './modules/distributors';
import AuthModule from './modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    products: ProductsModule,
    messages: MessagesModule,
    categories: CategoriesModule,
    distributors: DistributorsModule,
    auth: AuthModule,
  },
});
