<template lang="pug">
.products-list
  .row
    .col
      h1 Low Inventory
  .row
    .col
      b-pagination(
        v-model="currentPage",
        :total-rows="totalRows",
        :per-page="perPage"
      )
      p {{ paginationText }}
    .col
      b-input-group(:append="searchResults.length + ' products'")
        b-form-input(v-model="searchQuery", debounce="500")
  .row
    b-table(
      :items="searchResults",
      :per-page="perPage",
      :currentPage="currentPage",
      :fields="fields",
      :tbody-tr-class="rowClass",
      v-if="areProductsLoaded"
    )
      template(v-slot:cell(distributors)="data")
        div(
          v-for="distributor in getDistributors",
          :key="distributor.id",
          v-if="data.item.distributors.indexOf(distributor.id) > -1"
        ) {{ distributor.name }}
</template>

<script>
import { mapGetters } from 'vuex';
import { PRODUCTS_REQUEST } from '@/store/actions/products';
import { DISTRIBUTORS_REQUEST } from '@/store/actions/distributors';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default {
  name: 'product-list',
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      searchQuery: '',
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        'distributors',
        {
          key: 'cost',
          sortable: true,
          formatter: (val) => {
            if (val) {
              return currencyFormatter.format(val / 100);
            }
            return '';
          },
        },
        {
          key: 'current_stock',
          sortable: true,
        },
        {
          key: 'minimum_stock',
          label: 'Item Min Stock',
          sortable: true,
        },
        {
          key: 'category',
          formatter: (val) => {
            if (val) {
              return val.name;
            }
            return 'N/A';
          },
          sortable: true,
        },
        {
          key: 'category.minimum_stock',
          label: 'Category Min Stock',
          sortable: true,
        },
        'actions',
      ],
    };
  },
  methods: {
    rowClass: (item) => {
      if (item.needs_investigation) {
        return 'table-info';
      }
      if (
        item.current_stock <= item.minimum_stock
        || (item.category && item.current_stock <= item.category.minimum_stock)
      ) {
        return 'table-danger';
      }
      return 'table-success';
    },
  },
  created() {
    this.$store.dispatch(PRODUCTS_REQUEST);
    this.$store.dispatch(DISTRIBUTORS_REQUEST);
  },
  computed: {
    searchResults() {
      if (this.searchQuery !== '') {
        return this.getOutOfStockProducts.filter(
          (product) => product.name.toLowerCase().indexOf(this.searchQuery) > -1
            || (product.alternative_name
              && product.alternative_name.toLowerCase().indexOf(this.searchQuery)
                > -1)
            || (product.category
              && product.category.name.toLowerCase().indexOf(this.searchQuery)
                > -1)
            || product.clover_id.toLowerCase().indexOf(this.searchQuery) > -1,
        );
      }
      return this.getOutOfStockProducts;
    },
    totalRows() {
      return this.searchResults.length;
    },
    paginationText() {
      const startIndex = 1 + (this.currentPage - 1) * this.perPage;
      let pageCount = this.searchResults.slice(
        startIndex,
        startIndex + this.perPage - 1,
      ).length;
      if (pageCount === 1) {
        pageCount = 0;
      }
      const endIndex = startIndex + pageCount;

      return `${startIndex} - ${endIndex} of ${this.totalRows}`;
    },
    ...mapGetters([
      'areProductsLoaded',
      'getOutOfStockProducts',
      'getDistributors',
    ]),
  },
  filters: {},
  watch: {
    searchQuery(newQuery) {
      this.searchQuery = newQuery.toLowerCase();
    },
  },
};
</script>
