import axios from 'axios';
import {
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_SAVE,
  PRODUCTS_DELETE_REQUEST,
  PRODUCTS_DELETE_SUCCESS,
  PRODUCTS_UPDATED,
  PRODUCTS_SYNC,
} from '../actions/products';
import {
  MESSAGE_ADD,
} from '../actions/messages';
import {
  AUTH_LOGOUT,
} from '../actions/auth';
// eslint-disable-next-line import/prefer-default-export
const ProductsModule = {
  state: {
    products: [],
    status: '',
    searchQuery: '',
  },
  getters: {
    areProductsLoaded: (state) => state.products.length > 0,
    getProducts: (state) => state.products,
    getOutOfStockProducts: (state) => state.products.filter((product) => (product.track_stock && (product.current_stock <= product.minimum_stock || (product.category && product.current_stock <= product.category.minimum_stock)))),
    getTrackedProducts: (state) => state.products.filter((product) => product.track_stock),
    getNeedsInvestigationProducts: (state) => state.products.filter((product) => product.needs_investigation),
  },
  actions: {
    [PRODUCTS_REQUEST]: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      commit(PRODUCTS_REQUEST);
      axios({ url: `${process.env.VUE_APP_BASE_URL}/products/`, method: 'GET' })
        .then((resp) => {
          const { products } = resp.data;
          commit(PRODUCTS_SUCCESS, products);
          // dispatch(MESSAGE_ADD, { title: 'Products', body: 'Products have been loaded.', category: 'success' });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
          dispatch(MESSAGE_ADD, { title: 'Logged out', body: 'Your session has expired. Please log in again.', category: 'warning' });
          dispatch(AUTH_LOGOUT);
        });
    }),
    [PRODUCTS_SYNC]: ({ commit, dispatch }) => new Promise((resolve, reject) => {
      commit(PRODUCTS_SYNC);
      axios({ url: `${process.env.VUE_APP_BASE_URL}/products/sync`, method: 'POST' })
        .then((resp) => {
          const { products } = resp.data;
          commit(PRODUCTS_SUCCESS, products);
          dispatch(MESSAGE_ADD, { title: 'Products', body: 'Products have been loaded.', category: 'success' });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
    [PRODUCTS_SAVE]: ({ commit, dispatch }, product) => new Promise((resolve, reject) => {
      commit(PRODUCTS_SAVE);
      axios({ url: `${process.env.VUE_APP_BASE_URL}/products/${product.clover_id}`, data: product, method: 'POST' })
        .then((resp) => {
          commit(PRODUCTS_UPDATED);
          dispatch(MESSAGE_ADD, { title: 'Products', body: `${product.name} has been saved.`, category: 'success' });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
    [PRODUCTS_DELETE_REQUEST]: ({ commit, dispatch }, product) => new Promise((resolve, reject) => {
      commit(PRODUCTS_DELETE_REQUEST);
      axios({ url: `${process.env.VUE_APP_BASE_URL}/products/${product.clover_id}`, data: product, method: 'DELETE' })
        .then((resp) => {
          commit(PRODUCTS_UPDATED);
          commit(PRODUCTS_DELETE_SUCCESS, product);
          dispatch(MESSAGE_ADD, { title: 'Products', body: `${product.name} has been deleted.`, category: 'danger' });
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  },
  mutations: {
    [PRODUCTS_REQUEST]: (state) => {
      state.status = 'loading';
    },
    [PRODUCTS_SAVE]: (state) => {
      state.status = 'loading';
    },
    [PRODUCTS_DELETE_REQUEST]: (state) => {
      state.status = 'loading';
    },
    [PRODUCTS_DELETE_SUCCESS]: (state, product) => {
      state.status = 'loaded';
      state.products.splice(state.products.indexOf(product), 1);
    },
    [PRODUCTS_UPDATED]: (state) => {
      state.status = 'success';
    },
    [PRODUCTS_SUCCESS]: (state, products) => {
      state.status = 'success';
      state.products = products;
    },
  },
};
export default ProductsModule;
