<template lang="pug">
  .distributor-list
    .row
      .col
        h1 Distributors
      .col
        b-button(to="/distributor/new" variant="primary") Add New
    .row
      b-table(:items="getDistributors" :fields="fields" v-if="areDistributorsLoaded")
        template(v-slot:cell(number_of_products)="data") {{data.item.products.length}}
        template(v-slot:cell(number_of_tracked_products)="data") {{numTrackedProducts(data.item)}}
        template(v-slot:cell(number_of_low_products)="data") {{numLowProducts(data.item)}}
        template(v-slot:cell(actions)="data")
          b-button-group
            b-button(size="sm" :to="'/reports/distributors/' + data.item.id" variant="info") Report
            b-button(size="sm" :to="'/distributor/' + data.item.id" variant="success") Edit

</template>

<script>
import { mapGetters } from 'vuex';
import { DISTRIBUTORS_REQUEST } from '@/store/actions/distributors';

export default {
  name: 'distributors-list',
  data() {
    return {
      fields: [
        'name',
        'number_of_products',
        'number_of_tracked_products',
        'number_of_low_products',
        'actions',
      ],
    };
  },
  created() {
    this.$store.dispatch(DISTRIBUTORS_REQUEST);
  },
  methods: {
    numLowProducts(distributor) {
      return distributor.products.filter((product) => (product.product.current_stock <= product.product.minimum_stock || (product.category != null && product.product.current_stock <= product.product.category.minimum_stock))).length;
    },
    numTrackedProducts(distributor) {
      return distributor.products.filter((product) => (product.product.track_stock)).length;
    },
  },
  computed: {
    ...mapGetters(['areDistributorsLoaded', 'getDistributors']),
  },
  filters: {
  },
};
</script>
