<template lang="pug">
.products-list
  .row
    .col
      h1 Distributor Report
  .row
    .col
      b-pagination(
        v-model="currentPage",
        :total-rows="totalRows",
        :per-page="perPage"
      )
      p {{ paginationText }}
    .col
      b-input-group(:append="searchResults.length + ' products'")
        b-form-select(v-model="distributorId", :options="distributorsOptions")
      b-form-checkbox(v-model="onlyShowLow") Only low?
  .row
  .row
    b-table(
      :items="searchResults",
      :per-page="perPage",
      :currentPage="currentPage",
      :fields="fields",
      :tbody-tr-class="rowClass",
      v-if="areProductsLoaded"
    )
      template(v-slot:cell(distributors)="data")
        div(
          v-for="distributor in getDistributors",
          :key="distributor.id",
          v-if="data.item.distributors.indexOf(distributor.id) > -1"
        ) {{ distributor.name }}
</template>

<script>
import { mapGetters } from 'vuex';
import { PRODUCTS_REQUEST } from '@/store/actions/products';
import { DISTRIBUTORS_REQUEST } from '@/store/actions/distributors';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default {
  name: 'product-list',
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      distributorId: -1,
      onlyShowLow: false,
      fields: [
        {
          key: 'name',
          sortable: true,
        },
        'distributors',
        {
          key: 'cost',
          sortable: true,
          formatter: (val) => {
            if (val) {
              return currencyFormatter.format(val / 100);
            }
            return '';
          },
        },
        {
          key: 'current_stock',
          sortable: true,
        },
        {
          key: 'minimum_stock',
          label: 'Item Min Stock',
          sortable: true,
        },
        {
          key: 'category',
          formatter: (val) => {
            if (val) {
              return val.name;
            }
            return 'N/A';
          },
          sortable: true,
        },
        {
          key: 'category.minimum_stock',
          label: 'Category Min Stock',
          sortable: true,
        },
      ],
    };
  },
  methods: {
    rowClass: (item) => {
      if (item.needs_investigation) {
        return 'table-info';
      }
      if (
        item.current_stock <= item.minimum_stock
        || (item.category && item.current_stock <= item.category.minimum_stock)
      ) {
        return 'table-danger';
      }
      return 'table-success';
    },
  },
  created() {
    this.$store.dispatch(PRODUCTS_REQUEST);
    this.$store.dispatch(DISTRIBUTORS_REQUEST);
    if (this.$route.params.distributorId) {
      this.distributorId = parseInt(this.$route.params.distributorId, 10);
    }
  },
  computed: {
    searchResults() {
      let toFilter = this.getTrackedProducts;
      if (this.distributorId !== -1) {
        toFilter = toFilter.filter(
          (product) => product.distributors.indexOf(this.distributorId) > -1,
        );
      }
      if (this.onlyShowLow) {
        toFilter = toFilter.filter(
          (product) => product.current_stock <= product.minimum_stock
            || (product.category
              && product.current_stock <= product.category.minimum_stock),
        );
      }
      return toFilter;
    },
    distributorsOptions() {
      const options = [
        {
          value: -1,
          text: 'Choose',
          disabled: true,
        },
      ];
      this.getDistributors.forEach((distributor) => {
        options.push({
          value: distributor.id,
          text: distributor.name,
        });
      });
      return options;
    },
    totalRows() {
      return this.searchResults.length;
    },
    paginationText() {
      const startIndex = 1 + (this.currentPage - 1) * this.perPage;
      let pageCount = this.searchResults.slice(
        startIndex,
        startIndex + this.perPage - 1,
      ).length;
      if (pageCount === 1) {
        pageCount = 0;
      }
      const endIndex = startIndex + pageCount;

      return `${startIndex} - ${endIndex} of ${this.totalRows}`;
    },
    ...mapGetters([
      'areProductsLoaded',
      'getTrackedProducts',
      'getDistributors',
    ]),
  },
};
</script>
