import Vue from 'vue';
import VueRouter from 'vue-router';
import Products from '../views/Products.vue';
import Categories from '../views/Categories.vue';
import DistributorsList from '../views/DistributorsList.vue';
import DistributorEdit from '../views/DistributorEdit.vue';
import LowInventoryReport from '../views/reports/LowInventoryReport.vue';
import TrackedInventoryReport from '../views/reports/TrackedInventoryReport.vue';
import DistributorReport from '../views/reports/DistributorReport.vue';
import NeedsInvestigationReport from '../views/reports/NeedsInvestigationReport.vue';
import Login from '../views/Login.vue';
import store from '../store';

Vue.use(VueRouter);

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next();
    return;
  }
  next('/login');
};

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/products',
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Products',
    },
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories,
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Categories',
    },
  },
  {
    path: '/distributors',
    name: 'DistributorList',
    component: DistributorsList,
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'DistributorList',
    },
  },
  {
    path: '/distributor/:id',
    name: 'DistributorEdit',
    component: DistributorEdit,
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'DistributorEdit',
    },
  },
  {
    path: '/reports/low',
    name: 'Low Inventory Report',
    component: LowInventoryReport,
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Low Inventory Report',
    },
  },
  {
    path: '/reports/tracked',
    name: 'Tracked Inventory Report',
    component: TrackedInventoryReport,
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Tracked Inventory Report',
    },
  },
  {
    path: '/reports/investigation',
    name: 'Needs Investigation Report',
    component: NeedsInvestigationReport,
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'Needs Investigation Report',
    },
  },
  {
    path: '/reports/distributors/:distributorId',
    name: 'Distributors Report',
    component: DistributorReport,
    props: true,
    beforeEnter: ifAuthenticated,
    meta: {
      title: 'istributorRepor',
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
    meta: {
      title: 'Login',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});
// https://www.digitalocean.com/community/tutorials/vuejs-vue-router-modify-head
// This callback runs before every route change, including on page load.
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = `${nearestWithTitle.meta.title} | WC Inventory`;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll('[data-vue-router-controlled]'),
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
