<template lang="pug">
  .login
    b-row.justify-content-center
      b-col(md="6")
        h1 Login
        b-form(@submit.prevent="login")
          b-form-group(label="Password" label-for="password")
            b-input#password(type="password" v-model="password" required placeholder="password")
          b-button(type="submit" variant="primary") Submit

</template>

<script>
import { AUTH_REQUEST } from '@/store/actions/auth';

export default {
  name: 'login',
  data() {
    return {
      password: '',
    };
  },
  methods: {
    login() {
      const { password } = this;
      this.$store.dispatch(AUTH_REQUEST, { password }).then(() => {
        this.$router.push('/products');
      });
    },
  },
};
</script>
