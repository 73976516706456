<template lang="pug">
.category-list
  .row
    .col
      h1 Categories
  .row
    b-table(
      :items="getCategories",
      :fields="fields",
      v-if="areCategoriesLoaded"
    )
      template(v-slot:cell(is_livestock)="data")
        b-form-checkbox(
          v-model="data.item.is_livestock",
          @change="markDirty(data.item)"
        ) Livestock
      template(v-slot:cell(minimum_stock)="data")
        b-form-input(
          size="sm",
          v-model="data.item.minimum_stock",
          type="number",
          @change="markDirty(data.item)"
        )
      template(v-slot:cell(actions)="data")
        b-button(
          size="sm",
          @click="saveCategory(data.item)",
          variant="success"
        ) Save
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  CATEGORIES_REQUEST,
  CATEGORIES_SAVE,
} from '@/store/actions/categories';

export default {
  name: 'category-list',
  data() {
    return {
      dirtyItems: [],
      fields: [
        {
          key: 'clover_id',
          label: 'Clover ID',
        },
        'name',
        'minimum_stock',
        'is_livestock',
        'actions',
      ],
    };
  },
  methods: {
    markDirty(item) {
      this.dirtyItems.push(item);
    },
    saveDirty() {
      this.dirtyItems.forEach((category) => {
        this.$store.dispatch(CATEGORIES_SAVE, category);
      });
      Vue.set(this, 'dirtyItems', []);
    },
    saveCategory(product) {
      this.$store.dispatch(CATEGORIES_SAVE, product);
    },
  },
  created() {
    this.$store.dispatch(CATEGORIES_REQUEST);
  },
  computed: {
    ...mapGetters(['areCategoriesLoaded', 'getCategories']),
  },
  filters: {},
};
</script>
